import React from 'react'
import { Routes, Route } from "react-router-dom";

import { FinanzaScreen } from '../componentes/view/finanzas/FinanzaScreen'; 

import ListaOM from '../componentes/view/comercial/om/listOm'; 
import Abono from '../componentes/view/finanzas/abonos/abonos';

export const FinanzaRoutes = () => {

  return (
    <Routes>            
      <Route path="/" element={ <FinanzaScreen /> } />
      <Route path="abono" element={ <Abono /> } /> 
    </Routes>
  )
}

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faDownload } from '@fortawesome/free-solid-svg-icons';
import { TextField, Button, Table, TableHead, TableBody, TableCell, TableRow, Pagination } from '@mui/material';

function Abono() {
  const [datosGenerados, setDatosGenerados] = useState([]);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState(""); 
  const [filtroTexto, setFiltroTexto] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [elementosPorPagina] = useState(10);

  const fetchData = () => {
    const datos = {
      Token: "LJKNFUH2FWEJBAEFFFFFKJ VGDEA003CSFCAQWEUIRRRRRRRRRRRADFYGBIFHVBESRNYHIOPWJROTY4WY",
      Ambiente: "DESA",
      Usr: {
        Codigo: "",
        UserNickName: "pierril"
      },
      DatosMetodo: {
        NombreMetodo: "ListaAbonos",
        Datos_Recibidos: `{"FechaIni":"${fechaInicio}","FechaFin":"${fechaFin}"}`
      }
    };

    const fetchDataUrl = `https://appcorp.parma.com.gt/API/ReceptorDatos?Datos=${encodeURIComponent(JSON.stringify(datos))}`; 

    fetch(fetchDataUrl)
    .then(response => response.json())
    .then(data => {
      if (data && data.datos_Generados) {
        const datosGeneradosDecodificados = JSON.parse(data.datos_Generados);
        if (datosGeneradosDecodificados && datosGeneradosDecodificados.LISTABONO) {
          setDatosGenerados(datosGeneradosDecodificados.LISTABONO); 
        } else {
          setDatosGenerados([]);
        }
      } else {
        setDatosGenerados([]);
      }
    })
    .catch(error => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
  }, [fechaInicio, fechaFin]);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (event, value) => {
    setPaginaActual(value);
  };

  const filteredData = datosGenerados.filter(item => {
    const searchValue = filtroTexto.toLowerCase();
    return (
      item.OV.toLowerCase().includes(searchValue) ||
      item.Banco.toLowerCase().includes(searchValue) ||
      item.FormaPago.toLowerCase().includes(searchValue)
    );
  });

  const indexUltimoElemento = paginaActual * elementosPorPagina;
  const indexPrimerElemento = indexUltimoElemento - elementosPorPagina;
  const datosPaginados = filteredData.slice(indexPrimerElemento, indexUltimoElemento);

  const descargarImagen = (base64, extension, nombreArchivo) => {
    // Crear el string base64 completo
    const imagenBase64Completa = `data:image/${extension};base64,${base64}`;
    
    // Crear un enlace temporal para descargar la imagen
    const enlace = document.createElement('a');
    enlace.href = imagenBase64Completa;
    enlace.download = `${nombreArchivo}.${extension}`;
    enlace.click();
  };

  return (
    <div>
      <div style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
        <TextField
          id="filtroTexto"
          label="Buscar"
          value={filtroTexto}
          onChange={(e) => setFiltroTexto(e.target.value)}
          variant="outlined"
          style={{ marginBottom: '10px', width: '500px' }}
        />
        <TextField
          id="fechaInicio"
          label="Fecha de Inicio"
          type="date"
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <TextField
          id="fechaFin"
          label="Fecha de Fin"
          type="date"
          value={fechaFin}
          onChange={(e) => setFechaFin(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          style={{ marginRight: '10px' }}
        />
        <Button variant="contained" onClick={handleSearch} style={{ height: '55px', width: '100px' }}>
          Buscar
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Código Abono</TableCell>
            <TableCell>Tipo de Abono</TableCell>
            <TableCell>Banco</TableCell>
            <TableCell>Forma de Pago</TableCell>
            <TableCell>Orden de Venta</TableCell>
            <TableCell>Número de Referencia</TableCell>
            <TableCell>Monto</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Proceso</TableCell>
            <TableCell>Acciones</TableCell>
            <TableCell>Descargar Imagen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {datosPaginados.map(item => (
            <TableRow key={item.acov_codigo}>
              <TableCell>{item.acov_codigo}</TableCell>
              <TableCell>{item.TipoAbono}</TableCell>
              <TableCell>{item.Banco}</TableCell>
              <TableCell>{item.FormaPago}</TableCell>
              <TableCell>{item.OV}</TableCell>
              <TableCell>{item.NoReferencia}</TableCell>
              <TableCell>{item.Monto}</TableCell>
              <TableCell>
                {new Date(item.Fecha).toLocaleDateString('es-ES', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit'
                })}
              </TableCell>
              <TableCell>{item.Proceso}</TableCell>
              <TableCell>
                <a href={`detalleabono/${item.acov_codigo}`}>
                  <FontAwesomeIcon icon={faEdit} />
                </a>
              </TableCell>
              <TableCell>
                {item.B64Imagen && item.B64Extension ? (
                  <Button
                    variant="contained"
                    onClick={() => descargarImagen(item.B64Imagen, item.B64Extension, `imagen_abono_${item.acov_codigo}`)}
                    startIcon={<FontAwesomeIcon icon={faDownload} />}
                  >
                    Descargar
                  </Button>
                ) : (
                  'Sin Imagen'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(filteredData.length / elementosPorPagina)}
        page={paginaActual}
        onChange={handlePageChange}
        style={{ marginTop: '20px' }}
      />
    </div>
  );
}

export default Abono;

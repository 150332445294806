import React from 'react'
import { Routes, Route } from "react-router-dom";
import ListCA from '../componentes/view/acopios/centros/listca';


export const CentroAcopioRoutes = () => {

    return (
      <Routes>
       
        <Route path="dashboards/ca" element={ <ListCA /> } />
  
  
        
      </Routes>
    )
  }
  
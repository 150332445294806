import React from 'react'
import { Routes, Route } from "react-router-dom";

import { DatoScreen } from '../componentes/view/sala_venta/informacion_venta_cliente/DatoScreen';
import SalesExperienceClient from '../componentes/view/sala_venta/informacion_venta_cliente/SalesExperienceClient';

export const SalaVentaRoutes = () => {

  return (
    <Routes>
      <Route path="/dato_cliente_domicilio" element={ <DatoScreen /> } />
      <Route path="/datosClientes" element={ <SalesExperienceClient /> } />
    </Routes>
  )
}
